// in Edit Profile only key will be send to backend to store the document key in sever , 
//while using uploadDocument Api for uploading we are getting a full link, 
//so we will use sendLink function to remove the S3URl and before aacessing any link 
// we will use getLink function before accessing any link for document.

//https://pmt-documents-migration.s3.ap-south-1.amazonaws.com/
export const S3Url='https://brigosha-hiring-prod-brigosha-5777.s3.ap-south-1.amazonaws.com/'

export const getLink=(link)=>{

    

    if(link.includes(S3Url)){
        return link
    }
    else{
        return `${S3Url}${link}`
    }
}
export const sendLink=(link)=>{
   
    let linkR=link.replace(S3Url,'');
    return linkR
}
